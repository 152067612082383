import React, {useEffect} from "react";
import {
    AggregationInterval,
    ArgumentAxis,
    Chart,
    CommonSeriesSettings,
    Export,
    Format,
    Label,
    Legend,
    Series, Tooltip
} from "devextreme-react/chart";
import {AppDependencies} from "../../services/appDependencies";
import {IRoomSalesResponseChartLineDto} from "../../dtos/analytics/IRoomSalesResponseChartLineDto";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../hooks/reduxHooks";
import {getSelectedHotel} from "../../redux/slices/mainSlice";
import {IApartmentDto} from "../../dtos/apartments/IApartmentDto";
import {differenceInDays, endOfMonth, format, startOfMonth} from "date-fns";
import Select from "react-select";

interface IProps {
    startDate: Date,
    endDate: Date,
    selectedApartment: IApartmentDto | undefined;
}

interface IChartData {
    periodName: string;
    value: number;
}

interface ISelectOption {
    label: string;
    value: string;
}

const chartValueTypes: ISelectOption[] = [
    {
        label: "Room Revenue",
        value: "roomRevenueWithoutCommission"
    },
    {
        label: "Rooms Rented",
        value: "roomsRented"
    },
    {
        label: "Room Nights Sold",
        value: "roomNightsForSale"
    },
    {
        label: "ADR",
        value: "averageRoomPrice"
    },
    {
        label: "% Occupancy",
        value: "roomsRentedPercentByRooms"
    }
];


export const RoomSalesTurnover = ({startDate, endDate, selectedApartment}: IProps) => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | null>(null);
    const [tableData, setTableData] = React.useState<IRoomSalesResponseChartLineDto[]>([]);
    const [chartData, setChartData] = React.useState<IChartData[]>([]);
    const [currentChartValueType, setCurrentChartValueType] = React.useState<ISelectOption | null>(chartValueTypes[0]);
    const [categories, setCategories] = React.useState<string[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                setError(null);
                if (!selectedApartment || !currentChartValueType) {
                    setTableData([]);
                    return;
                }
                const response = await AppDependencies.AnalyticsService.GetRoomSalesInformation(selectedApartment.id, startDate, endDate);
                // console.log("room sales response", response)
                if (response) {
                    const newTableData: IRoomSalesResponseChartLineDto[] = [];
                    response.chartLines.map((chartLine) => {
                        const existingTableDataLine = newTableData.find((tableDataLine) => tableDataLine.roomName === chartLine.roomName);
                        if (existingTableDataLine) {
                            existingTableDataLine.roomRevenueWithoutCommission += chartLine.roomRevenueWithoutCommission;
                            existingTableDataLine.roomsRented += chartLine.roomsRented;
                            existingTableDataLine.roomNightsForSale += chartLine.roomNightsForSale;
                            existingTableDataLine.roomsRentedPercentByRooms += chartLine.roomsRentedPercentByRooms;
                        } else {
                            newTableData.push({
                                ...chartLine,
                                periodName: format(chartLine.period, "MMM yyyy"),
                            });
                        }
                    });
                    
                    const a = 1;
                    
                    response.chartLines.map((chartLine) => {
                        chartLine.roomsRentedPercentByRooms = chartLine.roomNightsForSale === 0 ? 0 : (chartLine.roomsRented / chartLine.roomNightsForSale) * 100;
                    });

                    newTableData.forEach((tableDataLine) => {
                        tableDataLine.averageRoomPrice = tableDataLine.roomsRented === 0 ? 0 : tableDataLine.roomRevenue / tableDataLine.roomsRented;
                        tableDataLine.roomNightsForSale = differenceInDays(endDate, startDate) + 1;
                        tableDataLine.roomsRentedPercentByRooms = tableDataLine.roomNightsForSale === 0 ? 0 : (tableDataLine.roomsRented / tableDataLine.roomNightsForSale) * 100;
                    });

                    setTableData(newTableData);

                    const newDraftChartData = response.chartLines.map((chartLine: any) => {
                        // console.log(chartLine);
                        // console.log("day diff", -differenceInDays(startOfMonth(chartLine.period), endOfMonth(chartLine.period)) + 1);
                        // console.log((differenceInDays(startOfMonth(chartLine.period), endOfMonth(chartLine.period)) + 1) === 0 ? 0 : (chartLine.roomsRented / differenceInDays(startOfMonth(chartLine.period), endOfMonth(chartLine.period)) + 1) * 100)
                        // console.log("rooms rented", chartLine.roomsRented);
                        // console.log("%", (chartLine.roomsRented / differenceInDays(startOfMonth(chartLine.period), endOfMonth(chartLine.period)) + 1) * 100)
                        const roomNightsForSale = differenceInDays(endDate, startDate) + 1;
                        return {
                            ...chartLine,
                            periodName: format(chartLine.period, "MMM yyyy"),
                            roomNightsForSale: -differenceInDays(startOfMonth(chartLine.period), endOfMonth(chartLine.period)) + 1,
                            roomsRentedPercentByRooms: (-differenceInDays(startOfMonth(chartLine.period), endOfMonth(chartLine.period)) + 1) === 0 ? 0 : (chartLine.roomsRented / roomNightsForSale * 100).toFixed(2)
                            // roomsRentedPercentByRooms: (-differenceInDays(startOfMonth(chartLine.period), endOfMonth(chartLine.period)) + 1) === 0 ? 0 : (chartLine.roomsRented / differenceInDays(startOfMonth(chartLine.period), endOfMonth(chartLine.period)) + 1) * 100
                        }
                    });

                    const newChartData = newDraftChartData.map((chartLine: any) => {
                        return {
                            periodName: format(chartLine.period, "MMM yyyy"),
                            value: chartLine[currentChartValueType.value]
                        }
                    });

                    const newCategories: Date[] = [];
                    response.chartLines.forEach((chartLine) => {
                        if (!newCategories.includes(chartLine.period)) {
                            newCategories.push(chartLine.period);
                        }
                    });
                    const uniqueSortedCategories = newCategories.sort((aDate, bDate) => {
                        return aDate.getTime() - bDate.getTime();
                    });
                    const newStringCategories = uniqueSortedCategories.map((date) => {
                        return format(date, "MMM yyyy");
                    });

                    setCategories(newStringCategories);
                    setChartData(newChartData);
                    console.log(newChartData);
                    console.log(newTableData);
                    
                } else {
                    setError("Error fetching data");
                }
            } catch (e: any) {
                setError(e.message);
            } finally {
                setIsLoading(false);
            }

        }
        fetchData();
    }, [selectedApartment, startDate, endDate, currentChartValueType]);

    if (!selectedApartment) {
        return <></>
    }

    return (
        <>
            {error && <div className={"alert alert-danger"}>{error}</div>}
            <div className="row">
                <div className="col-md-12">
                    <Select options={chartValueTypes} value={currentChartValueType}
                            onChange={(e) => setCurrentChartValueType(e)}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 overflow-auto">
                    <Chart id="chart"
                           title="Room Sales Turnover"
                           dataSource={chartData}
                    >
                        <CommonSeriesSettings
                            argumentField="periodName"
                            type="line"
                            hoverMode="allArgumentPoints"
                            selectionMode="allArgumentPoints"
                        >
                        </CommonSeriesSettings>
                        <ArgumentAxis argumentType={"string"} aggregateByCategory={true} categories={categories}>
                            <Label format="monthAndYear" />
                        </ArgumentAxis>
                        <Series 
                            valueField="value"
                            name={currentChartValueType?.label}
                        >
                            <Label visible={true}>
                                <Format type="fixedPoint" precision={2} />
                            </Label>
                        </Series>
                        <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
                        <Tooltip enabled={true}/>
                        <Export enabled={true}/>
                    </Chart>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 overflow-auto">
                    <table className="table table-striped table-bordered">
                        <thead>
                        <tr>
                            <th>Room</th>
                            {/*<th>Revenue</th>*/}
                            <th>Revenue</th>
                            <th>Rented</th>
                            <th>Room Nights for Sale</th>
                            <th>ADR</th>
                            <th>% Occupancy</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableData.map((item, index) => (
                            <tr key={index}>
                                <td>{item.roomName}</td>
                                {/*<td>{item.roomRevenue.toFixed(2)}</td>*/}
                                <td>{item.roomRevenueWithoutCommission.toFixed(2)}</td>
                                <td>{item.roomsRented.toFixed(2)}</td>
                                <td>{item.roomNightsForSale.toFixed(2)}</td>
                                <td>{item.averageRoomPrice.toFixed(2)}</td>
                                <td>{item.roomsRentedPercentByRooms.toFixed(2)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}