import axios from "axios";
import {toast} from "react-hot-toast";

const devApi = "https://localhost:5001/api";
const testApi = "https://ng-test.soft-unity.com/api";
const prodApi = "https://myportal.nextgroup.ge/api";
export const currentApi = testApi;


export const axiosApi = axios.create({
    baseURL: currentApi,
});

export const maintenanceMode = false;


